// import { SET_VENUES_LIST, SET_VENUES_DETAILS, SET_SPORTS } from '../constants/actionTypes';

const { httpUtils, API_METHODS, mapsUtil } = require("../util/httpUtils");
// const v2Api = "https://api.playo.io";
const Auth =
   process.env.NEXT_PUBLIC_IO_API_AUTH || "5534898698eb3426d00168b6ed447d23d000026552ed6200";
const baseUrl = process.env.NEXT_PUBLIC_IO_API_BASE_URL || "https://playo.io/api/";
/*prod*/

const v2Api = process.env.NEXT_PUBLIC_IO_V2_API || "https://staging.playo.io";

const fetchAllVenues = async (body, dispatch) => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/venue-public/v2/list",
      authorization: Auth,
      body
   });

   return res;
};

const fetchVenues = async (queryParams, dispatch) => {
   queryParams = encodeURI(queryParams);

   const res = await httpUtils({
      method: "get",
      url: v2Api + "/venue-public/details/?" + queryParams,
      authorization: Auth
   });

   return res;
   // dispatch({
   //    type: SET_VENUES_DETAILS,
   //    payload: res
   // });
};

const fetchAllSports = async dispatch => {
   const res = await httpUtils({
      method: "get",
      //  /user/sports/all
      url: "https://playo.io/api/v1/sports/",
      authorization: Auth
   });
   return res;

   // dispatch({
   //    type: SET_SPORTS,
   //    payload: res
   // });
};

const venueSearch = async (data, query) => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/venue-public/search?page=${query.page}&query=${query.query}`,
      // authorization: token,
      body: data
   });

   return res;
};
const searchEventsVenues = async (data, query) => {
   const res = await httpUtils({
      method: "get",
      url:
         v2Api +
         `/venue-public/v2/search?lat=${data.lat}&lng=${data.lng}&searchQuery=${query.query}&category=${data.category}`,
      // authorization: token,
      authorization: Auth,
      body: data
   });

   return res;
};

const sendLink = async data => {
   const res = await httpUtils({
      method: "post",
      url: baseUrl + "web/sendLink/",
      // authorization: token,
      body: data
   });

   return res;
};

const createTicket = async data => {
   const res = await httpUtils({
      method: "post",
      url: "https://playo.zendesk.com/api/v2/tickets.json",
      authorization: "Bearer 8819ecf51ea6209b4690ba4149b1d424815ef906dd70ba1dfa32e5f505704cfd",
      body: data
   });

   return res;
};

const rateVenue = async (data, token) => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/venue/rating",
      authorization: token,
      body: data
   });

   return res;
};

const generateOtp = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/onboard/v2/generateOTP",
      body: data
   });

   return res;
};

const generateEmailOtp = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/onboard/email/generateOTP",
      body: data
   });

   return res;
};

const verifyOtp = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/onboard/v2/verifyOTP",
      body: data
   });

   return res;
};

const verifyEmailOtp = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/onboard/email/web/verifyOTP",
      body: data
   });

   return res;
};

const googleSignIn = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/onboard/v2/signIn",
      body: data
   });

   return res;
};

const saveLogin = async data => {
   const res = await httpUtils({
      method: "post",
      url: "/api/login",
      authorization: Auth,
      body: data
   });

   return res;
};

const logout = async () => {
   const res = await httpUtils({
      method: "get",
      url: "/api/logout",
      authorization: Auth
   });

   return res;
};

const getCountries = async () => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + "/utils/thirdparty/countries",
      authorization: Auth
   });

   return res;
};

const getAddress = async (lat, lng) => {
   const res = await mapsUtil({
      method: "post",
      url:
         "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
         lat +
         "," +
         lng +
         "&key=AIzaSyB0jIS7vLvHGwAY-moXqraeffWEnFOzZ2g"
   });

   return res;
};

const getLatLng = async address => {
   const res = await mapsUtil({
      method: "post",
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAjXCSYKlakKqTRb12_AA2rbMIhMP6DGRM`
   });

   return res;
};

const updateUser = async (data, token) => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/user/profile/update",
      authorization: token,
      body: data
   });

   return res;
};

const sendQuery = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/venue/enquiry",
      authorization: Auth,
      body: data
   });

   return res;
};

const sendEventEnquiry = async data => {
   const res = await httpUtils({
      method: "post",
      url: baseUrl + "web/v1/inquiry/",
      authorization: Auth,
      body: data
   });

   return res;
};

const eventLeadGen = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/explore-public/enquiry",
      authorization: Auth,
      body: data
   });

   return res;
};

const submitFeedback = async data => {
   const res = await httpUtils({
      method: "post",
      url: baseUrl + "v1/user/help",
      authorization: Auth,
      body: data
   });

   return res;
};

const getBanners = async queryParams => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/playoads/banner?platform[]=web&countryCode=${encodeURIComponent(queryParams)}`,
      authorization: Auth
   });
   return res;
};

const fetchASports = async queryParams => {
   queryParams = encodeURI(queryParams);

   const res = await httpUtils({
      method: "get",
      url: baseUrl + "v2/sport/" + queryParams + "/",
      authorization: Auth
   });

   return res;
   // dispatch({
   //    type: SET_VENUES_DETAILS,
   //    payload: res
   // });
};

const getActivityData = async queryParams => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + "/activity-public/info/" + queryParams + "?publicDetails=true",
      authorization: Auth
   });

   return res;
   // dispatch({
   //    type: SET_VENUES_DETAILS,
   //    payload: res
   // });
};

const getGameTimeAreas = async queryParams => {
   const res = await httpUtils({
      method: "get",
      url:
         v2Api + "/venue-public/gametime/cities?lat=" + queryParams.lat + "&lng=" + queryParams.lng,
      authorization: Auth
   });

   return res;
};

const getProfileData = async queryParams => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + "/user-public/profile/" + queryParams,
      authorization: Auth
   });

   return res;
};

const getLocationActiListing = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/activity-public/list/location",
      body: data,
      authorization: Auth
   });

   return res;
};

const getGroupData = async groupId => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + "/group-public/v2/info/" + groupId,
      authorization: Auth
   });

   return res;
   // dispatch({
   //    type: SET_VENUES_DETAILS,
   //    payload: res
   // });
};
const getVenueActiListing = async (data, venueId) => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/activity-public/list/venue/" + venueId,
      body: data,
      authorization: Auth
   });

   return res;
};

const getUserCalendar = async queryParams => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + "/activity-public/upcoming/" + queryParams + "?all=false",
      authorization: Auth
   });

   return res;
};

const getEventDetails = async query => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + "/explore/" + query,
      authorization: Auth
   });

   return res;
};

const getVenuesOffer = async query => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + "/venue-public/allOffers/" + query,
      authorization: Auth
   });

   return res;
};

const uploadProfilePicture = async (data, token) => {
   const formData = new FormData();
   formData.append("uploadFile", data.uploadFile, data.fileName);
   formData.append("fileName", data.fileName);

   const res = await httpUtils({
      method: "post",
      url: v2Api + "/utils/image-upload/user/profiles",
      authorization: token,
      body: formData
   });

   return res;
};

const getRedisBanners = async data => {
   const res = await httpUtils({
      method: "get",
      url: "/api/redisUpdate"
   });

   return res.data;
};

const fetchMetaData = async key => {
   const res = await httpUtils({
      method: "get",
      url: "/api/metadata?key=" + key
   });

   return res.data;
};

const getVenueActivity = async venueId => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/activity-public/list/venue/${venueId}`
   });

   return res;
};

const getHomePageVenues = async data => {
   const res = await httpUtils({
      url: v2Api + `/venue-public/home/list`,
      method: "post",
      body: data,
      authorization: Auth
   });

   return res.data;
};

const getHomePageActivities = async data => {
   const res = await httpUtils({
      url: v2Api + `/activity-public/home/list`,
      method: "post",
      body: data,
      authorization: Auth
   });

   return res;
};

const getHomePageBanners = async data => {
   const res = await httpUtils({
      url:
         v2Api +
         `/user-public/homescreen?lat=${data.lat}&lng=${data.lng}&countryCode=${data.countryCode}`,
      method: "get",
      authorization: Auth
   });

   return res;
};

const activityDetailsData = async activityId => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/activity-public/info/` + activityId,
      authorization: Auth
   });
   return res;
};

const sendJoinQuery = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/activity/query",
      authorization: Auth,
      body: data
   });

   return res;
};

const sendClevertapEvent = async data => {
   const res = await httpUtils({
      method: "post",
      url: "/api/clevertap/event",
      body: data
   });

   return res;
};
const sendJoinGame = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/activity/join",
      authorization: Auth,
      body: data
   });

   return res;
};

const sendClevertapProfile = async data => {
   const res = await httpUtils({
      method: "post",
      url: "/api/clevertap/profile",
      body: data
   });

   return res;
};

// const payAndJoinVerify = async data => {
//    const res = await httpUtils({
//       method: "post",
//       url: "https://staging.playo.club/book-api/v1/custom/verify/",
//       authorization: "JKEA4rcdAFoY7FzmD0lm7qkKzCM=",
//       body: data
//    });

//    return res;
// };

// const payAndJoinCreateOrder = async data => {
//    const res = await httpUtils({
//       method: "post",
//       url: "https://staging.playo.club/book-api/v1/activity/order/",
//       authorization: "JKEA4rcdAFoY7FzmD0lm7qkKzCM=",
//       body: data
//    });

//    return res;
// };
const updateUserSkill = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/user/sports",
      authorization: Auth,
      body: data
   });

   return res;
};

const sendClevertapData = async data => {
   const res = await httpUtils({
      method: "post",
      url: "/api/clevertap",
      body: data
   });

   return res;
};
const cancelActivityJoinRequest = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/activity/v2/revoke",
      authorization: Auth,
      body: data
   });

   return res;
};
const acceptActivityJoinRequest = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/activity/accept",
      authorization: Auth,
      body: data
   });

   return res;
};
const cancelRequestActivityJoinRequest = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/activity/v2/revoke",
      authorization: Auth,
      body: data
   });

   return res;
};
const getTrainerListings = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/trainer-public/list",
      authorization: Auth,
      body: data
   });

   return res;
};
const getTrainerHomeData = async ({ lat, lng, userId }) => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer-public/home?lat=${lat}&lng=${lng}`,
      authorization: Auth
   });

   return res;
};
const getTrainerHomeSuggestionsData = async data => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + "/trainer-public/suggestions",
      authorization: Auth,
      body: data
   });

   return res;
};
const getTrainerHomeSearchData = async (data, lat, lng) => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer-public/search?searchQuery=${data}&lat=${lat}&lng=${lng}`,
      authorization: Auth,
      body: data
   });

   return res;
};
const getTrainerDetails = async data => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer-public/details?trainerId=${data}`,
      authorization: Auth,
      body: data
   });

   return res;
};
const getTrainerDetailsWithUserId = async ({ userId, trainerId }) => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer/details?trainerId=${trainerId}&userId=${userId}`,
      authorization: Auth,
      body: { userId, trainerId }
   });

   return res;
};
const getTrainerTestimonials = async data => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer-public/testimonials?trainerId=${data}`,
      authorization: Auth,
      body: data
   });

   return res;
};
const getTrainerCategoryDetails = async serviceId => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer/service/details?serviceId=${serviceId}`,
      authorization: Auth,
      body: serviceId
   });

   return res;
};

const getTrainerQueries = async userId => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer/user/queries?userId=${userId}`,
      authorization: Auth,
      body: userId
   });

   return res;
};

const updateQueriesRead = async body => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/trainer/read/query`,
      authorization: Auth,
      body
   });

   return res;
};
const trainerSendQuery = async body => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/trainer/enquiry`,
      authorization: Auth,
      body
   });

   return res;
};

const rateTrainer = async body => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/trainer/review`,
      authorization: Auth,
      body
   });

   return res;
};

const getRateTrainer = async ({ userId, trainerId }) => {
   const res = await httpUtils({
      method: "get",
      url: v2Api + `/trainer/review?trainerId=${trainerId}&userId=${userId}`,
      authorization: Auth
   });

   return res;
};

const updateRatingTrainer = async body => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/trainer/editReview`,
      authorization: Auth,
      body
   });

   return res;
};

const postPortalEnquiry = async body => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/tsp-onboarding/captureLeads`,
      body
   });

   return res.data;
};

const getSeoClientData = async key => {
   const res = await httpUtils({
      method: "get",
      url: `/api/contentful/getClientSeoData?key=${key}`
   });

   return res.data;
};


const postUserActive = async body => {
   const res = await httpUtils({
      method: "post",
      url: v2Api + `/user/active`,
      authorization: Auth,
      body
   });

   return res.data;
}


export const IO_API_SERVICE = {
   fetchAllVenues,
   generateOtp,
   verifyOtp,
   fetchVenues,
   fetchAllSports,
   getAddress,
   rateVenue,
   venueSearch,
   saveLogin,
   logout,
   updateUser,
   getCountries,
   sendQuery,
   getBanners,
   fetchMetaData,
   submitFeedback,
   createTicket,
   sendLink,
   fetchASports,
   getActivityData,
   getProfileData,
   getLocationActiListing,
   getGroupData,
   getVenueActiListing,
   getUserCalendar,
   getEventDetails,
   sendEventEnquiry,
   eventLeadGen,
   searchEventsVenues,
   getVenuesOffer,
   generateEmailOtp,
   verifyEmailOtp,
   googleSignIn,
   uploadProfilePicture,
   getRedisBanners,
   getVenueActivity,
   getHomePageActivities,
   getHomePageVenues,
   getHomePageBanners,
   getGameTimeAreas,
   activityDetailsData,
   sendJoinQuery,
   sendJoinGame,
   updateUserSkill,
   cancelActivityJoinRequest,
   acceptActivityJoinRequest,
   cancelRequestActivityJoinRequest,
   sendClevertapEvent,
   sendClevertapProfile,
   sendClevertapData,
   getLatLng,
   getTrainerListings,
   getTrainerHomeData,
   getTrainerHomeSuggestionsData,
   getTrainerHomeSearchData,
   getTrainerDetails,
   getTrainerDetailsWithUserId,
   getTrainerTestimonials,
   getTrainerCategoryDetails,
   trainerSendQuery,
   getTrainerQueries,
   rateTrainer,
   getRateTrainer,
   updateRatingTrainer,
   updateQueriesRead,
   postPortalEnquiry,
   getSeoClientData,
   postUserActive
};
